<template>
	<div>
		<v-row v-if="loading">
			<v-col cols="12" md="6" lg="3" v-for="loader in 8" :key="loader">
				<v-skeleton-loader
					v-bind="attrs"
					type="card"
					height="400"
					width="100%"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<div v-else>
			<div class="tw-pb-6">
				<div class="tw-flex tw-items-center tw-justify-between">
					<h2 class="tw-text-xl tw-font-semibold tw-mb-7">
						{{ $t('top-selling-products') }}
					</h2>
					<div>
						<v-btn
							@click="filterDrawer = !filterDrawer"
							class="tw-rounded tw-w-12 tw-h-12 tw-me-4"
							color="#D8D8D8"
							icon
							outlined
							tile
						>
							<v-icon color="black">mdi mdi-sort-ascending</v-icon>
						</v-btn>
						<v-btn
							@click="sortByDrawer = !sortByDrawer"
							class="tw-rounded tw-w-12 tw-h-12"
							color="#D8D8D8"
							icon
							outlined
							tile
						>
							<v-icon color="black">mdi mdi-filter</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="tw-flex tw-flex-wrap tw-space-s-4 lg:tw-space-s-9">
					<!-- <v-card v-for="i in 6" :key="i">
					<v-skeleton-loader
						class="tw-w-24"
						min-width="280"
						type="card"
					></v-skeleton-loader>
				</v-card> -->
				</div>
				<v-row class="tw-mt-9">
					<v-col
						v-for="(item, i) in $topSellingProducts.data"
						:key="i"
						cols="12"
						sm="6"
						md="4"
						lg="3"
						xl="2"
					>
						<ProductCard
							@onClick="goToSingleProduct"
							@onHover="handleOnHover"
							@fetchFavorite="refetchFavorite"
							:productInfo="
								selectedType == 'products' ? item : item.products[0]
							"
							class="tw-flex-none"
							:isGreyQuantityBg="false"
						/>
					</v-col>
					<!-- {{filterSelectedProduct.data}} -->
				</v-row>

				<v-dialog
					content-class="tw-rounded-2xl tw-shadow-none"
					v-model="showProductDialog"
					transition="dialog-bottom-transition"
					max-width="1020"
				>
					<v-card
						v-if="onHoverProduct"
						elevation="0"
						class="tw-rounded-2xl"
					>
						<div
							class="tw-pb-3 tw-pt-4 lg:tw-pb-7 lg:tw-pt-10 tw-ps-20 tw-pe-12"
						>
							<v-row>
								<v-col cols="12" md="6" class="tw-flex tw-flex-col">
									<h1 class="tw-font-bold tw-text-4xl tw-truncate">
										{{ onHoverProduct.name }}
									</h1>
									<span class="tw-text-lg">{{
										onHoverProduct.description
									}}</span>
									<div
										class="md:tw-mt-16 md:tw-mb-24 tw-my-6 tw-py-4 tw-border-t tw-border-b tw-border-E5 tw-flex-1"
									>
										<v-row>
											<v-col cols="3">
												<div>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('seller') }}</span
													>
													<h3
														class="tw-font-semibold tw-text-sm tw-mt-2 tw-mb-3"
													>
														{{
															onHoverProduct.supplier
																.representativeName
														}}
													</h3>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('availability') }}</span
													>
													<h3
														class="tw-font-medium tw-text-sm tw-mt-2.5 tw-text-black tw-font-Poppins-text"
													>
														{{ onHoverProduct.status }}
													</h3>
												</div>
											</v-col>
											<v-col cols="3">
												<div>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('pieces') }}</span
													>
													<h3
														class="tw-font-semibold tw-text-sm tw-mt-2 tw-mb-3"
													>
														{{ $t('one-piece') }}
													</h3>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('sizes') }}</span
													>
													<h3
														class="tw-font-medium tw-text-sm tw-mt-2.5 tw-text-black tw-font-Poppins-text"
													>
														S-M-L
													</h3>
												</div>
											</v-col>

											<v-col cols="3">
												<div>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('size') }}</span
													>
													<h3
														class="tw-font-semibold tw-text-sm tw-mt-2 tw-mb-3"
													>
														{{ $t('small') }}
													</h3>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('category') }}</span
													>
													<h3
														class="tw-font-medium tw-text-sm tw-mt-2.5 tw-text-black tw-font-Poppins-text"
														v-if="
															onHoverProduct.mainCategory &&
															onHoverProduct.mainCategory.length
														"
													>
														{{
															onHoverProduct.mainCategory[0].name
														}}
													</h3>
												</div>
											</v-col>

											<v-col cols="3">
												<div>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('headers.color') }}</span
													>
													<h3
														class="tw-font-semibold tw-text-sm tw-mt-2 tw-mb-3"
													>
														{{ $t('black') }}
													</h3>
													<span
														class="tw-text-11px tw-font-light"
														>{{ $t('subCategory') }}</span
													>
													<h3
														class="tw-font-medium tw-text-sm tw-mt-2.5 tw-text-black tw-font-Poppins-text"
														v-if="
															onHoverProduct.subCategory &&
															onHoverProduct.subCategory.length
														"
													>
														{{
															onHoverProduct.subCategory[0].name
														}}
													</h3>
												</div>
											</v-col>
										</v-row>
									</div>
									<div
										class="tw-flex tw-items-center tw-justify-between tw-flex-none"
									>
										<div class="tw-flex tw-items-end">
											<h2
												class="tw-text-3xl tw-font-medium tw-font-Rubik-text"
											>
												{{ formatPrice(onHoverProduct.price) }}
											</h2>
											<span class="tw-ms-3">SAR</span>
										</div>
										<div
											class="tw-rounded-md tw-space-s-4 tw-flex tw-items-center"
										>
											<div
												class="tw-flex tw-items-center tw-sp tw-bg-F8 tw-rounded-md"
											>
												<v-btn
													@click="quantity -= 1"
													:disabled="quantity <= 1"
													tile
													class
													icon
												>
													<v-icon>mdi-minus</v-icon>
												</v-btn>
												<v-divider
													style="border-color: #f0f0f0 !important"
													vertical
												></v-divider>
												<input
													min="1"
													class="tw-w-10 tw-text-center tw-text-xs tw-px-2 tw-text-black tw-font-semibold"
													v-model="quantity"
												/>

												<v-divider
													style="border-color: #f0f0f0 !important"
													vertical
												></v-divider>
												<v-btn @click="quantity += 1" icon>
													<v-icon color="black">mdi-plus</v-icon>
												</v-btn>
											</div>
											<v-btn
												dark
												depressed
												color="#F5CA15"
												class="tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10 cart-btn"
												tile
											>
												<v-icon size="15">mdi mdi-cart</v-icon>
											</v-btn>
										</div>
									</div>
								</v-col>
								<v-col cols="12" md="6">
									<div>
										<img
											class="tw-rounded-10px tw-h-308px tw-w-full"
											:src="
												onHoverProduct.attachments &&
												onHoverProduct.attachments.length
													? $image(
															onHoverProduct.attachments[
																selectedImage
															].attachedLink
													  )
													: ''
											"
											alt
											srcset
										/>
									</div>
									<div class="tw-flex tw-mt-3.5 tw-space-s-3">
										<!-- {{onHoverProduct.attachments}} -->
										<div
											v-for="(
												image, i
											) in onHoverProduct.attachments || []"
											:key="i"
										>
											<v-img
												:src="$image(image.attachedLink)"
												:class="{
													'tw-border-F2 tw-border-2 tw-bg-F2':
														i === selectedImage,
												}"
												alt
												ref="hImgRef"
												class="tw-h-28 tw-w-28 tw-object-cover tw-object-center tw-rounded-10px"
												@click="selectedImage = i"
												@error="
													$onImgError({
														index: i,
														ref: $refs.hImgRef,
													})
												"
											/>
										</div>
										<!-- <img
										class="tw-w-36 tw-h-28 tw-rounded-10px"
										src="https://picsum.photos/200/300"
										alt
										srcset
									/>
									<img
										class="tw-w-36 tw-h-28 tw-rounded-10px"
										src="https://picsum.photos/200/300"
										alt
										srcset
									/>-->
									</div>
								</v-col>
							</v-row>
						</div>
					</v-card>
				</v-dialog>
				<v-card elevation="0" class="tw-rounded-3xl" v-model="sortByDrawer">
					<v-navigation-drawer
						class="2xl:tw-rounded-3xl lg:tw-rounded-xl tw-rounded-lg tw-w-64 md:tw-w-80 2xl:tw-w-420"
						v-model="sortByDrawer"
						fixed
						temporary
						right
					>
						<div
							class="tw-px-4 md:tw-px-10 tw-pt-6 md:tw-pt-10 xl:tw-pt-16"
						>
							<div class="tw-flex tw-items-center tw-space-s-10">
								<v-btn
									@click="sortByDrawer = !sortByDrawer"
									class="tw-w-12 tw-h-12"
									icon
								>
									<v-icon large>mdi-chevron-left</v-icon>
								</v-btn>
								<h3 class="tw-text-2xl tw-font-semibold">
									{{ $t('sort-by') }}
								</h3>
							</div>
							<div class="tw-mt-5 xl:tw-mt-11">
								<v-radio-group v-model="sortBy">
									<v-radio
										color="#F2CA51 tw-text-black"
										class="tw-text-xl tw-mt-6"
										:value="'highToLow'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												{{ $t('high-to-low-price') }}
											</div>
										</template>
									</v-radio>
									<v-radio
										color="#F2CA51"
										class="tw-text-xl tw-mt-6"
										:value="'lowToHigh'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												{{ $t('low-to-high-price') }}
											</div>
										</template>
									</v-radio>
									<v-radio
										color="#F2CA51"
										class="tw-text-xl tw-mt-6"
										:value="'newItems'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												{{ $t('new-items') }}
											</div>
										</template>
									</v-radio>
									<!-- <v-radio
									color="#F2CA51"
									class="tw-text-xl tw-mt-6"
									:value="'recommended'"
								>
									<template v-slot:label>
										<div class="tw-text-xl tw-text-black">
											Highly Recommended
										</div>
									</template>
								</v-radio> -->
								</v-radio-group>
							</div>
						</div>
						<template v-slot:append>
							<div
								block
								class="tw-mx-4 lg:tw-mx-10 tw-mb-4 md:tw-mb-8 xl:tw-mb-14"
							>
								<v-btn
									class="tw-rounded-lg md:tw-h-14"
									depressed
									dark
									color="#722432"
									block
									@click="sortProduts"
								>
									<span
										class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
										>{{ $t('sort-result') }}</span
									>
								</v-btn>
							</div>
						</template>
					</v-navigation-drawer>
				</v-card>
				<v-card elevation="0" class="tw-rounded-3xl" v-model="filterDrawer">
					<v-navigation-drawer
						class="tw-rounded-3xl tw-w-64 md:tw-w-80 2xl:tw-w-420"
						v-model="filterDrawer"
						fixed
						temporary
						right
					>
						<div class="tw-px-4 md:tw-px-10 tw-pt-16">
							<div class="tw-flex tw-items-center tw-space-s-10">
								<v-btn
									@click="filterDrawer = !filterDrawer"
									class="tw-w-12 tw-h-12"
									icon
								>
									<v-icon large>mdi-chevron-left</v-icon>
								</v-btn>
								<h3 class="tw-text-2xl tw-font-semibold">
									{{ $t('filters') }}
								</h3>
							</div>
							<div>
								<v-text-field
									height="54"
									class="custom-outline-text-field tw-rounded-md tw-my-4 lg:tw-my-11"
									outlined
									hide-details="false"
									v-model="filters.query"
								>
									<v-icon slot="prepend-inner" color="#413434"
										>mdi-magnify</v-icon
									>
								</v-text-field>
								<!-- <div>
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										Show Categories
									</h2>
									<div
										class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
									>
										<div
											v-for="(category, i) in $productCategories.data"
											:key="i"
											@click="selectedCategoryId = category.id"
											class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
											:class="
												category.id == selectedCategoryId
													? 'tw-bg-F2 '
													: 'tw-border tw-border-E9 '
											"
										>
											<span
												:class="
													category.id == selectedCategoryId
														? ' tw-text-white'
														: 'tw-text-black tw-opacity-70'
												"
												class="tw-text-base"
												>{{ category.name }}</span
											>
										</div>
									</div>
								</div>
								<div
									v-if="
										selectedCategory &&
											selectedCategory.childCategories &&
											selectedCategory.childCategories.length
									"
									class="lg:tw-mt-8 tw-mt-4"
								>
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										Sub Categories
									</h2>
									<div
										class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
									>
										<div
											v-for="(subCategory,
											i) in selectedCategory.childCategories"
											:key="i"
											:class="
												subCategory.id == filters.subCategory
													? 'tw-bg-F2 '
													: 'tw-border tw-border-E9 '
											"
											class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
											@click="filters.subCategory = subCategory.id"
										>
											<span
												:class="
													subCategory.id == filters.subCategory
														? ' tw-text-white'
														: 'tw-text-black tw-opacity-70'
												"
												class="tw-text-base"
												>{{ subCategory.name }}</span
											>
										</div>
									</div>
								</div> -->
								<div class="lg:tw-my-8 tw-my-4">
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										{{ $t('price-range') }}
									</h2>
									<v-slider
										tick-size="32"
										track-color="#F4F4F4"
										class="price-range"
										thumb-color="#FFFFFF"
										hide-details="auto"
										color="#F2CA51"
										v-model="filters.price_range_up"
										hint="Im a hint"
										:min="minRange"
										:max="maxRange"
									></v-slider>
									<div
										class="tw-flex tw-justify-between tw-items-center tw-px-2 tw-mt-3"
									>
										<span class="tw-font-Rubik-text tw-text-D2"
											>{{ minRange }} {{ $t('sar') }}</span
										>
										<span class="tw-text-F2 tw-font-Rubik-text"
											>{{ filters.price_range_up }}
											{{ $t('sar') }}</span
										>
										<span class="tw-font-Rubik-text tw-text-D2"
											>{{ maxRange }} {{ $t('sar') }}</span
										>
									</div>
								</div>
							</div>
						</div>
						<template v-slot:append>
							<div block class="tw-mx-4 lg:tw-mx-10 tw-mb-14">
								<v-btn
									class="tw-rounded-lg md:tw-h-14"
									depressed
									@click="filterProducts"
									dark
									color="#722432"
									block
								>
									<span
										class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
										>{{ $t('sort-result') }}</span
									>
								</v-btn>
							</div>
						</template>
					</v-navigation-drawer>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import ProductCard from '../components/customs/ProductCard.vue'
import { mapActions, mapGetters } from 'vuex'
import { numberWithCommas } from '../helpers'

export default {
	name: 'Products',
	components: {
		ProductCard,
	},
	data() {
		return {
			selectedCategoryId: null,
			selectedType: 'products',
			showProductDialog: false,
			quantity: 1,
			onHoverProduct: null,
			sortByDrawer: false,
			loading: true,
			filters: {
				query: '',

				price_range_up: null,
			},

			minRange: 0,
			maxRange: 80000,
			sortBy: '',
			filterDrawer: false,
			selectedImage: 0,
		}
	},

	mounted() {},

	computed: {
		...mapGetters('products', ['$topSellingProducts']),
		...mapGetters('category', ['$productCategories']),
		...mapGetters('offers', ['$offers']),
		items() {
			return [
				{ title: this.$t('home-text'), icon: 'mdi-home-city' },
				{ title: this.$t('my-account'), icon: 'mdi-account' },
				{ title: this.$t('users'), icon: 'mdi-account-group-outline' },
			]
		},
	},
	methods: {
		...mapActions('products', ['getTopSellingProducts']),
		...mapActions('category', ['getCategories']),
		...mapActions('offers', ['getRunningOffers']),

		handleOnHover(item) {
			this.showProductDialog = true
			this.onHoverProduct = item
		},
		handleOnLeave() {
			this.showProductDialog = false
			setTimeout(() => {
				this.onHoverProduct = null
			}, 1000)
		},
		formatPrice(price) {
			console.log(price)
			return numberWithCommas(price)
		},
		goToSingleProduct(id) {
			console.log('goToSingleProduct')
			this.$router.push(`product/${id}`)
		},
		async sortProduts() {
			let priceOrderBy = this.sortBy === 'lowToHigh' ? 'asc' : 'desc'
			let orderByColumn = this.sortBy === 'newItems' ? 'created_at' : 'price'
			console.log(priceOrderBy, orderByColumn, 'priceOrderBy')
			// this.getTopSellingProducts({ priceOrderBy: this.sortBy,orderByColumn:priceOrderBy });
			this.getTopSellingProducts({
				priceOrderBy: priceOrderBy,
				orderByColumn: orderByColumn,
				mainCategory: this.selectedCategoryId,
			})
		},
		async filterProducts() {
			this.getTopSellingProducts({
				...this.filters,
				mainCategory: this.selectedCategoryId,
			})
		},
		refetchFavorite() {
			console.log('refetchFavorite')
		},
	},
	created() {
		this.getTopSellingProducts()

		this.getRunningOffers()
		console.log()
		this.loading = false
	},
}
</script>

<style lang="scss">
// .custom-outline-text-field .v-text-field--outlined >>> fieldset {
// 	border-color: rgba(192, 0, 250, 0.986);
// }
.custom-outline-text-field.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.price-range .v-slider__thumb {
	background-color: rgb(255, 255, 255);
	border-color: #d9d9d9 !important;
	border: 1px solid;
	width: 32px !important;
	height: 32px !important;
}
.price-range .v-slider__track-container {
	height: 8px !important;
}
.price-range .v-slider__track-fill,
.v-slider__track-background {
	border-radius: 6px !important;
}
.price-range .v-slider__thumb:after {
	display: block;
	width: 18px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	margin-top: 1px;
}
</style>
